<template>
  <base-section
    id="pro-features3"
    class="pt-0"
    style="font-size: large"
  >
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            elevation="16"
            class="mb-12"
          >
            <base-img
              max-width="100%"
              src="/imgs/img3.jpeg"
            />
          </v-card>

          <!--          <div class="text-center">-->
          <!--            <base-btn-->
          <!--              :tile="false"-->
          <!--              color="primary"-->
          <!--              href="https://store.vuetifyjs.com/products/zero-theme-pro/?ref=vtyd-pro-page-features"-->
          <!--              rounded-->
          <!--            >-->
          <!--              Buy Pro Now-->

          <!--              <v-icon right>-->
          <!--                mdi-rocket-->
          <!--              </v-icon>-->
          <!--            </base-btn>-->
          <!--          </div>-->
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          Создавайте быстро и гибко настраивайте параметры заданий:
          место, время, дополнительная информация. Планируйте задания на любой период. Назначайте исполнителей,
          комплектуйте бригады, отслеживайте историю и проставляйте результаты.
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProFeat',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>
